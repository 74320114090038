import React from 'react';
import PATH from '../../utils/constants/path';

const context = {
  clickedSection: '',
  currentSection: PATH.ABOUT,
  updateCurrentSection: () => {},
  updateClickedSection: () => {},
};

const LayoutContext = React.createContext(context);

export default LayoutContext;
