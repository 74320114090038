import PATH from './path';

export default [
  { path: PATH.ABOUT, label: 'About' },
  { path: PATH.CHARACTERS, label: 'Characters' },
  // { path: PATH.GAMEPLAY, label: 'Gameplay' },
  { path: PATH.ROADMAP, label: 'Roadmap' },
  { path: PATH.TEAM, label: 'Team' },
  { path: PATH.FAQ, label: 'FAQ' },
  { path: PATH.STAKING, label: 'Staking' },
];
