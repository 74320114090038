import React, { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';
import Layout from './components/Layout';
import LoadingView from './components/LoadingView';
import PATH from './utils/constants/path';
import { loader as characterLoader } from './views/Character';

const Main = lazy(() => import('./views/Main'));
const Character = lazy(() => import('./views/Character'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Suspense fallback={<LoadingView />}><Main /></Suspense>,
      },
      {
        path: `${PATH.CHARACTERS}/:character`,
        element: <Suspense fallback={<LoadingView />}><Character /></Suspense>,
        loader: characterLoader,
      },
    ],
  },
]);

export default router;
