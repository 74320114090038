import React, { useContext } from 'react';
import { Wrapper, NavItem } from './styles';
import LayoutContext from '../../../context';

function NavBar(props) {
  const { updateClickedSection } = useContext(LayoutContext);
  const { items = [] } = props;

  return (
    <Wrapper>
      <ul>
        {items.map(({ path, label }) => (
          <li key={path}>
            <NavItem
              onClick={() => updateClickedSection(path)}
              href={`/#${path}`}
              className={
                window.location.href.includes(path)
                  ? 'isActive'
                  : ''
              }
            >
              {label}
            </NavItem>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
}

export default NavBar;
