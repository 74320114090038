import React from 'react';
import { Icon, Wrapper } from './styles';

function SocialMedia(props) {
  const { data = [] } = props;

  const handleClick = (link) => {
    if (!link) {
      window.alert('Coming soon...');
      return;
    }
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.rel = 'noreferrer noopener';
    a.click();
  };

  return (
    <Wrapper>
      {data.map(({ icon, link, alt }) => (
        <Icon type="button" onClick={() => handleClick(link)} key={alt}>
          <img src={icon} alt={alt} />
        </Icon>
      ))}
    </Wrapper>
  );
}

export default SocialMedia;
