import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const TabBarWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow-x: scroll;
  background: #000;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

export const TabBar = styled.button`
  font-family: "CFB1 American Patriot";
  color: #fff;
  background: #000;
  cursor: pointer;
  font-size: 1.25em;
  border: none;
  padding: 1em 1.5em;
  width: 100%;

  &.active {
    background: linear-gradient(96.97deg, #6333A6 -1.26%, #230749 106.69%, #331063 107.96%);
  }
`;

export const TabPane = styled.ul`
  padding-inline-start: 1em;
  line-height: 1.5;
  font-size: 1.25em;
  gap: 2em;
  
  li {
    margin-top: 2em;
  }
`;
