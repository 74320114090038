import React from 'react';
import { Wrapper } from './styles';

function MenuButton(props) {
  const { onClick } = props;
  return (
    <Wrapper type="button" onClick={onClick}>
      <span>&#9776;</span>
    </Wrapper>
  );
}

export default MenuButton;
