import React, { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import {
  Name,
  RadialBackground,
  Wrapper,
  MobileViewStyle,
  ImageSection,
  ImageSectionBorder,
  InnerWrapper,
  InfoSection,
} from './styles';
import Tab from './components/Tab';
import useWindowSize from '../../hooks/useWindowSize';

function Character() {
  const { width } = useWindowSize();
  const showDesktopView = useMemo(() => width > 1024, [width]);
  const character = useLoaderData();
  const {
    image = '',
    name = '',
    backgrounds = [],
    traits = [],
    missions = [],
  } = character || {};
  const items = [
    { key: 'background', label: 'Background', content: backgrounds },
    { key: 'traits', label: 'Personality/Traits', content: traits },
    { key: 'missions', label: 'Missions', content: missions },
  ];

  return (
    <Wrapper responsiveCss={showDesktopView ? null : MobileViewStyle}>
      <RadialBackground />
      <InnerWrapper>
        <ImageSection>
          <img src={image} alt={name} />
          <ImageSectionBorder />
        </ImageSection>
        <InfoSection>
          <Name>{name}</Name>
          <Tab items={items} />
        </InfoSection>
      </InnerWrapper>
    </Wrapper>
  );
}

export default Character;
