import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
`;

export const Icon = styled.button`
  background-color: #000;
  outline: none;
  border: none;
  border-radius: 50%;
  height: 3.5em;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: rgb(180,80,229);
    background: linear-gradient(180deg, rgba(180,80,229,1) 0%, rgba(105,80,229,1) 100%);
  }

  img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: contain;
  }
`;
