import styled from 'styled-components';

export const Wrapper = styled.nav`
  ul {
    list-style: none;
    display: flex;
    gap: 3em;
    margin: 0;
    padding: 0;
  }
`;

export const NavItem = styled.a` 
  border: none;
  color: #fff;
  position: relative;
  background: none;
  cursor: pointer;
  font-family: "CFB1 American Patriot";
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background: rgb(180,80,229);
    background: linear-gradient(180deg, rgba(180,80,229,1) 0%, rgba(105,80,229,1) 100%);
    border-radius: 5px;
    transform: scale(0, 1);
    transition: transform 0.25s ease;
    transform-origin: 0 100%;
    left: 0;
    bottom: -0.5em;
  }

  &.isActive, &:hover {
    background: rgb(180,80,229);
    background: linear-gradient(180deg, rgba(180,80,229,1) 0%, rgba(105,80,229,1) 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    position: relative;

    &::after {
      transform: scale(1, 1);
    }
  }
`;
