import styled, { css } from 'styled-components';

export const DesktopViewStyle = css`
  display: flex;
  justify-content: center;
  height: 80px;
`;

export const MobileViewStyle = css`
  display: flex;
  justify-content: space-between;
  height: 60px;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0.5em 0;
  background-color: rgba(24,26,32,0.5);
  position: fixed;
  top: 0;
  z-index: 3;
  ${({ responsiveCss }) => responsiveCss};
`;

export const DesktopViewWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
`;

export const Logo = styled.a`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  
  img {
    height: 100%;
    max-height: 90px;
    object-fit: contain;
  }
`;

export const MobileViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  width: 100%;
  padding: 0 1em;
`;
