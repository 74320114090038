function getMediaFromS3(key) {
  return `${process.env.REACT_APP_S3_URL}${key}`;
}

function round(value, precision) {
  const multiplier = 10 ** (precision || 0);
  return (Math.round(value * multiplier) / multiplier).toFixed(precision);
}

// eslint-disable-next-line import/prefer-default-export
export { getMediaFromS3, round };
