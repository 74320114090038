const ABOUT = 'about';
const CHARACTERS = 'characters';
const GAMEPLAY = 'gameplay';
const ROADMAP = 'roadmap';
const TEAM = 'team';
const FAQ = 'faq';
const STAKING = 'staking';

export default {
  ABOUT,
  CHARACTERS,
  GAMEPLAY,
  ROADMAP,
  TEAM,
  FAQ,
  STAKING,
};
