import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  width: 400px;
  max-width: 100vw;
  height: 100vh;
  top: 0;
  right: -350px;
  background-color: rgba(24,26,32, 1);
  display: flex;
  flex-direction: column;
  z-index: 999;
  
  &.active {
    transform: translateX(-350px);
    transition: ease-in 0.8s;
    overflow: hidden;
  }

  &.inactive {
    transform: translateX(350px);
    transition: ease-out 0.8s;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2em;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 2em 2em 0 0;
  border-radius: 5px;
  transition: 300ms;
  
  span {
    display: block;
    color: #fff;
    font-size: 2em;
    font-weight: 600;
  }
  
  &:hover {
    background-color: #fff;
    span {
      color: rgba(24,26,32, 1);;
    }
    transition: 300ms;
  }
`;

export const Nav = styled.nav`
  width: 100%;
  padding: 1em 0;
  
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3em;
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;
