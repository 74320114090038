import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import {
  ErrorMessage, Logo, SorryMessage, Warning, Wrapper,
} from './styles';
import { getMediaFromS3 } from '../../utils/functions/helpers';

function ErrorPage() {
  const error = useRouteError();

  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <Wrapper backgroundImg={getMediaFromS3('about_background.png')}>
      <Logo src={getMediaFromS3('about_logo.png')} />
      <Warning>Oops!</Warning>
      <SorryMessage>Sorry, an unexpected error has occurred.</SorryMessage>
      <ErrorMessage>{`Error message: ${error.statusText || error.message}`}</ErrorMessage>
    </Wrapper>
  );
}

export default ErrorPage;
