import React, { useContext } from 'react';
import {
  CloseButton,
  Wrapper,
  Nav,
  Header,
} from './styles';
import { NavItem } from '../Header/components/NavBar/styles';
import menu from '../../../utils/constants/menu';
import LayoutContext from '../context';

function DrawerMenu({ isSideMenuOpen, toggleSideMenu }) {
  const { updateClickedSection } = useContext(LayoutContext);

  return (
    <Wrapper className={isSideMenuOpen ? 'active' : 'inactive'}>
      <Header>
        <CloseButton type="button" onClick={toggleSideMenu}>
          <span>&#10005;</span>
        </CloseButton>
      </Header>
      <Nav>
        <ul>
          {menu.map(({ path, label }) => (
            <li key={path}>
              <NavItem
                href={`/#${path}`}
                className={
                  window.location.href.includes(path)
                    ? 'isActive'
                    : ''
                }
                onClick={() => updateClickedSection(path)}
              >
                {label}
              </NavItem>
            </li>
          ))}
        </ul>
      </Nav>
    </Wrapper>
  );
}

export default DrawerMenu;
