import React, { useEffect, useState } from 'react';
import {
  TabBar, TabBarWrapper, TabPane, Wrapper,
} from './styles';

function Tab(props) {
  const [selectedTab, setSelectedTab] = useState(null);
  const [listItems, setListItems] = useState([]);
  const { items } = props;

  function onClickTabBar(key) {
    const selectedItem = items.find(({ key: tabKey }) => key === tabKey);
    setSelectedTab(key);
    setListItems(selectedItem?.content || []);
  }

  useEffect(() => {
    const defaultKey = items[0]?.key;
    const selectedItem = items.find(({ key: tabKey }) => defaultKey === tabKey);
    setSelectedTab(defaultKey || null);
    setListItems(selectedItem?.content || []);
  }, [items]);

  return (
    <Wrapper>
      <TabBarWrapper>
        {items.map(({ key, label }) => (
          <TabBar
            type="button"
            key={key}
            className={selectedTab === key ? 'active' : 'inactive'}
            onClick={() => onClickTabBar(key)}
          >
            {label}
          </TabBar>
        ))}
      </TabBarWrapper>
      <TabPane>
        {listItems.map((item) => <li>{item}</li>)}
      </TabPane>
    </Wrapper>
  );
}

export default Tab;
