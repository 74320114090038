import React, { useContext, useMemo } from 'react';
import {
  DesktopViewStyle,
  DesktopViewWrapper, Logo, MobileViewStyle, MobileViewWrapper, Wrapper,
} from './styles';
import NavBar from './components/NavBar';
import SocialMedia from './components/SocialMedia';
import useWindowSize from '../../../hooks/useWindowSize';
import MenuButton from './components/MenuButton';
import SOCIAL_MEDIA from '../../../utils/constants/socialMedia';
import MENU from '../../../utils/constants/menu';
import PATH from '../../../utils/constants/path';
import { getMediaFromS3 } from '../../../utils/functions/helpers';
import LayoutContext from '../context';

function Header({ toggleSideMenu }) {
  const { width } = useWindowSize();
  const showDesktopView = useMemo(() => width >= 1024, [width]);
  const { updateClickedSection } = useContext(LayoutContext);

  return (
    <Wrapper responsiveCss={showDesktopView ? DesktopViewStyle : MobileViewStyle}>
      {showDesktopView ? (
        <DesktopViewWrapper>
          <Logo
            onClick={() => updateClickedSection(PATH.ABOUT)}
            href={`/#${PATH.ABOUT}`}
          >
            <img src={getMediaFromS3('logo.png')} alt="RadiNation" />
          </Logo>
          <NavBar items={MENU} />
          <SocialMedia data={SOCIAL_MEDIA} />
        </DesktopViewWrapper>
      ) : (
        <MobileViewWrapper>
          <Logo
            onClick={() => updateClickedSection(PATH.ABOUT)}
            href={`/#${PATH.ABOUT}`}
          >
            <img src={getMediaFromS3('logo.png')} alt="RadiNation" />
          </Logo>
          <MenuButton onClick={toggleSideMenu} />
        </MobileViewWrapper>
      )}
    </Wrapper>
  );
}

export default Header;
