import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 2em;
  background-color: #0F0F16;
  background-image: ${({ backgroundImg }) => `url('${backgroundImg}')`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
`;

export const Logo = styled.img`
  max-width: 300px;
`;

export const ErrorMessage = styled.span`
  font-size: 1.25em;
`;
