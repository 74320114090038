import React, { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import DrawerMenu from './DrawerMenu';
import { Wrapper } from './styles';
import LayoutContext from './context';

function Layout() {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(false);
  const [clickedSection, setClickedSection] = useState(false);

  const toggleSideMenu = () => {
    setIsSideMenuOpen((prev) => !prev);
  };

  const updateCurrentSection = (sectionId) => {
    setCurrentSection(sectionId);
    window.location.hash = `#${sectionId}`;
  };

  const updateClickedSection = (sectionId) => {
    setClickedSection(sectionId);
    setIsSideMenuOpen(false);
  };

  const contextValue = useMemo(() => ({
    clickedSection,
    currentSection,
    updateCurrentSection,
    updateClickedSection,
  }), [clickedSection, currentSection]);

  return (
    <Wrapper>
      <LayoutContext.Provider value={contextValue}>
        <Header toggleSideMenu={toggleSideMenu} />
        <Outlet id="outlet" />
        <DrawerMenu toggleSideMenu={toggleSideMenu} isSideMenuOpen={isSideMenuOpen} />
      </LayoutContext.Provider>
    </Wrapper>
  );
}

export default Layout;
