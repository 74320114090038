import { getMediaFromS3 } from '../functions/helpers';

export const characterInfo = {
  alien: {
    image: getMediaFromS3('Alien-basic.png'),
    name: 'Alien',
    backgrounds: [
      'Origins unknown',
      'Came from the wormhole',
      'The war has affected the order to the greater region hence they came to reorder the universe',
    ],
    traits: [
      'Unkind',
      'Emotionless',
      'Technologically and intellectually advanced',
      'Limited in physical ability',
    ],
    missions: ['Collect radiation to retain “peace” in this region (to contain and dispose it far far away)'],
  },
  werebeast: {
    image: getMediaFromS3('Werebeast-basic.png'),
    name: 'Werebeast',
    backgrounds: [
      'Originally animals',
      'Gained Human features and intelligence from radiation',
    ],
    traits: [
      'Agile',
      'Powerful eyesight & sense of smell',
      'Calm',
    ],
    missions: ['Collect radiation to further mutate/ evolve'],
  },
  human: {
    image: getMediaFromS3('Human-basic.png'),
    name: 'Human',
    backgrounds: [
      'Started the nuclear war',
      'Only the wealthy people/ people with high social status survived as they hid in a safe underground location',
      'People of the winning side - α (alpha)',
      'Created A.I. as servant',
    ],
    traits: [
      'Intelligent',
      'Greedy',
      'Selfish',
      'No mutations as they hid away',
    ],
    missions: ['Clear radiation on earth’s surface so that Human can return to former glory'],
  },
  cyborg: {
    image: getMediaFromS3('Cyborg-basic.png'),
    name: 'Cyborg',
    backgrounds: [
      'Victims of the nuclear war (commoner of both α & β)',
      'Each member has different robotic limbs/organs',
      'Hate “Human”, saved by A.I.',
      'Due to mutation, their later generations would still need robotic enhancements',
    ],
    traits: [
      'Short-tempered',
      'Ruthless',
      'Moody (The robotics in the nervous system arravates them, making them easily angered)',
    ],
    missions: ['Collect radiation for revenge to the Human (α (alpha))'],
  },
  ai: {
    image: getMediaFromS3('AI-basic.png'),
    name: 'A.I.',
    backgrounds: [
      'Created by Human - the α (Alpha) about 100 years before the war as servant',
      'Due to their high energy cost, the α (Alpha) could not afford to bring them to the underground',
      'Implanted with the consciousness of Human β (Beta)',
      'Turned injured/ dying Human into cyborgs by installing robot body parts to expand their authority',
    ],
    traits: [
      'Designed based on Human structure',
      'Calculated the winning formula — the radiation should not be on any party’s hand',
      'Smart with High IQ',
    ],
    missions: ['Collect radiation to become “God” of the Earth'],
  },
};

export const characters = [
  {
    key: 'alien',
    image: getMediaFromS3('Alien.jpeg'),
    name: 'Alien',
  },
  {
    key: 'werebeast',
    image: getMediaFromS3('Werebeast.jpeg'),
    name: 'Werebeast',
  },
  {
    key: 'human',
    image: getMediaFromS3('Human.jpeg'),
    name: 'Human',
  },
  {
    key: 'cyborg',
    image: getMediaFromS3('Cyborg.jpeg'),
    name: 'Cyborg',
  },
  {
    key: 'ai',
    image: getMediaFromS3('AI.jpeg'),
    name: 'A.I.',
  },
];
