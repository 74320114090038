import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const LoadingAnimation = styled.div`
  display: flex;
  color: #fff;
  font-size: 2em;

  .l {
    animation: bounce 3s ease infinite;
  }

  .o {
    animation: bounce 3s ease infinite .1s;
  }

  .a {
    animation: bounce 3s ease infinite .2s;
  }

  .d {
    animation: bounce 3s ease infinite .3s;
  }

  .i {
    animation: bounce 3s ease infinite .4s;
  }

  .n {
    animation: bounce 3s ease infinite .5s;
  }

  .g {
    animation: bounce 3s ease infinite .6s;
  }

  .dot-1 {
    animation: bounce 3s ease infinite .7s;
  }

  .dot-2 {
    animation: bounce 3s ease infinite .8s;
  }

  .dot-3 {
    animation: bounce 3s ease infinite .9s;
  }

  @keyframes bounce {
    0%   { transform: translateY(0); }
    10%  { transform: translateY(0); }
    20%  { transform: translateY(-10px);}
    30%  { transform: translateY(0); }
    40%  { transform: translateY(-10px); }
    55%  { transform: translateY(0);}
    65%  { transform: translateY(0);}
    75%  { transform: translateY(-20px);}
    85%  { transform: translateY(0);}
    95%  { transform: translateY(-10px);}
    100% { transform: translateY(0);}
  }
`;
