import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #1A1A1A;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export default { Wrapper };
