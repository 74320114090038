import React from 'react';
import { ErrorMessage, Logo, Wrapper } from './styles';
import { getMediaFromS3 } from '../../utils/functions/helpers';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { state, props } = this;
    const { children } = props;
    const { hasError } = state;

    if (hasError) {
      return (
        <Wrapper backgroundImg={getMediaFromS3('about_background.png')}>
          <Logo src={getMediaFromS3('about_logo.png')} />
          <ErrorMessage>Oops! Something went wrong.</ErrorMessage>
        </Wrapper>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
