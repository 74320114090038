import React from 'react';
import {
  LoadingAnimation, Wrapper,
} from './styles';

function LoadingView() {
  return (
    <Wrapper>
      <LoadingAnimation>
        <div className="l">L</div>
        <div className="o">O</div>
        <div className="a">A</div>
        <div className="d">D</div>
        <div className="i">I</div>
        <div className="n">N</div>
        <div className="g">G</div>
        <div className="dot-1">.</div>
        <div className="dot-2">.</div>
        <div className="dot-3">.</div>
      </LoadingAnimation>
    </Wrapper>
  );
}

export default LoadingView;
