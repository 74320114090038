import styled from 'styled-components';

export const Wrapper = styled.button`
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 40px;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  border-radius: 5px;

  span {
    display: block;
    font-size: 3em;
    color: #fff;
  }
  
  &:hover {
    background-color: rgba(24,26,32, 1);
    transition: 300ms;
  }
`;

export default { Wrapper };
