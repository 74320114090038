import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 10vh 0;
  position: relative;
  z-index: 1;
  ${({ responsiveCss }) => responsiveCss};
`;

export const RadialBackground = styled.div`
  position: absolute;
  bottom: 20%;
  left: 30%;
  background: linear-gradient(180deg, rgba(180,80,229,1) 0%, rgba(105,80,229,1) 100%);
  width: 40%;
  aspect-ratio: 1;
  border-radius: 50%;
  filter: blur(15vw);
  opacity: 0.5;
  z-index: -1;
`;

export const InnerWrapper = styled.div`
  display: flex;
  padding-bottom: 10vh;
  width: 100%;
`;

export const ImageSection = styled.div`
  background-color: rgba(255,255,255,0.1);
  backdrop-filter: blur(2px);
  border-radius: 20px;
  position: relative;
  width: 50vw;
  max-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -5em;
  animation-name: slideIn;
  animation-duration: 2s;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 20px;
  }

  @keyframes slideIn {
    from {
      transform: translate(40%, 100vh) scale(0.5);
    }
    to {
      transform: translate(0, 0) scale(1);
    }
  }
`;

export const ImageSectionBorder = styled.div`
  border-radius: 20px;
  border: 1px solid transparent;
  background: linear-gradient(180deg, rgba(180,80,229,1) 0%, rgba(105,80,229,1) 100%) border-box;
  overflow: hidden;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

export const InfoSection = styled.div`
  color: #fff;
  padding-right: 2em;
`;

export const Name = styled.span`
  display: block;
  font-size: 3em;
  margin-bottom: 0.5em;
`;

export const MobileViewStyle = css`
  ${Wrapper} {
    padding-top: 0;
  }
  
  ${RadialBackground} {
    width: 60%;
    left: 20%;
    filter: blur(20vw);
    bottom: 40%;
  }
  
  ${InnerWrapper} {
    flex-direction: column;
    align-items: center;
  }
  
  ${ImageSection} {
    width: 60%;
    height: auto;
    left: 0;
    padding: 5vw;
    margin-bottom: 3em;
  }
  
  ${InfoSection} {
    width: 80%;
    font-size: 0.65em;
  }
`;
